import {find, findAll, findAllIn, findIn, on, closest} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope";

export function initDetailedSearchTracking(){
    onFind(".js-detail-search-tracking", (wrapper) => {
        let form = closest(".js-detail-search__form", wrapper);
        let locale = wrapper.getAttribute('value');
        let event = "detailed_search_"+locale

        let data = {
            'event': event
        }

        on('change', (filter) => {
            if (filter.target.type === "checkbox" && filter.target.checked){
                //checkboxes
                let filtername = filter.target.id.split('[')[0];
                let value = filter.target.value;

                data['selected_filter'] = filtername.toLowerCase();
                data['filter_value'] = value;

                window.dataLayer.push(data)
            }else if (filter.target.type === "text"){
                //input field - from to for length
                let filtername = filter.target.name;
                let value = filter.target.value;

                data['selected_filter'] = filtername.toLowerCase();
                data['filter_value'] = value;

                window.dataLayer.push(data)
            }
        }, form)
    })
}

export function initEcommerceTracking(){
    onFind(".js-ecommerce-tracking", function(button){
        on("click", function(event){
            let keys = JSON.parse(button.getAttribute('data-tracking-keys'));

            keys.map((key) => {
                let data = _trackingData[key]["datalayer"];

                if (data) {
                    if (data.event === 'add_payment_info') {
                        let payment = find(".js-ecommerce-tracking__payment:checked")

                        if(payment){
                            data.ecommerce = {...data.ecommerce, 'payment_type': payment.value};
                        }
                    } else if (data.event === 'add_shipping_info') {
                        let shipping = find(".js-ecommerce-tracking__shipping:checked")

                        if(shipping){
                            data.ecommerce = {...data.ecommerce, 'shipping_tier': shipping.value};
                        }
                    }

                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    window.dataLayer.push(data);
                }
            })
        }, button)
    })
}