"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import Tooltip from "bootstrap/js/dist/tooltip";
import axios from "axios";

export function init() {
    let pendingRequest;

    onFind('[data-bs-toggle="tooltip"]',function (element) {
        //init default tooltip
        new Tooltip(element);

        //load dynamic tooltip content
        if(element.classList.contains('js-dynamic-tooltip-toggle')) {
            element.addEventListener('shown.bs.tooltip', function () {
                let url = element.getAttribute('data-tooltip-url');
                pendingRequest = axios({method: 'GET', url: url });

                pendingRequest.then((response) => {
                    let result = response.data;

                    if (result.success) {
                        let tooltip = document.querySelectorAll('.js-dynamic-tooltip');
                        if(tooltip.length > 0 && result.html) {
                            tooltip[0].innerHTML = result.html;
                        }
                    }
                }).catch((error) => {
                    if (error.name !== 'AbortError') {
                        /*Do error stuff*/
                        console.error(error);
                    }
                });
            })
        }
    });
}