import Autocomplete from '../libs/autocomplete.esm.js';
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import axios from "axios";

const selectors = {
    base: '.js-autocomplete-sales',
};

// the input with the base class un-/checks all other inputs with the child class that are in the container
export function init() {
    onFind(selectors.base, (autocompleteEl) => {

        let remoteUrl = autocompleteEl.dataset.autocompleteRemoteUrl;
        let wildcard = "%QUERY%";
        // get dataset as object
        let categories = JSON.parse(autocompleteEl.dataset.autocompleteCategories);
        new Autocomplete("salesSearch", {
            clearButton: false,
            onSearch: ({ currentValue }) => {
                const api = remoteUrl.replace(wildcard, encodeURIComponent(currentValue));
                return axios.get(api)
                        .then((response) => {
                            return response.data;
                        })
                        .catch((error) => {
                            console.error(error);
                        });
            },
            onResults: ({ currentValue, matches, template, classGroup }) => {
                let items = [];
                // loop through the categories and create the result items
                categories.map((category) => {
                    let categoryItems = matches[category.name];

                    // if we have items for the category OR if it is the searchSuggestions category (which is always shown)
                    if (categoryItems && categoryItems.length !== 0) {
                        let tempItems = '';

                        let header = `<div class="autocomplete__dataset-header mb-4">
                                            <span class="autocomplete__dataset-header-hl">
                                            <span class="autocomplete__dataset-header-icon icon icon-user-regular"></span>${category.title}</span>
                                      </div>`;

                        tempItems = categoryItems.map((data, index, array) => {
                            // create the regex for the highlight
                            let regexp = new RegExp(currentValue, 'gi');
                            if (data.title) {
                            return `<a  href="${data.url}" class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item"
                                         data-event-tracking-event="website_search" data-event-tracking-search_query="${currentValue}"
                                         data-event-tracking-result_type="search_suggestion" data-event-tracking-clicked_result="${data.title}">
                                        ${data.title.replace(regexp, '<mark>$&</mark>')}
                                    </a>`
                            }
                        }).join('');

                        // search Suggestions are shown in the left area
                        items.push(`<div class="autocomplete__dataset">${header}${tempItems}</div>`);
                    }else {
                        let header = `<div class="autocomplete__dataset-header">
                                            <span class="autocomplete__dataset-header-hl">${translate("no-results")}</span>
                                      </div>`;
                        items.push(`<div class="autocomplete__dataset">${header}</div>`);
                    }
                }); // end of categories.map

                // checking if we have results if we don't
                // take data from the noResults callback
                return matches === 0
                    ? template
                    : `<li class="autocomplete__sales">${items.join('')}</li>`;
            },
            onOpened: ({results}) => {
                initInScope(results);
            },
        });

    });
}
