import {onFind} from "@elements/init-modules-in-scope";
import {on, closest} from "@elements/dom-utils";
import axios from "axios";
import Modal from "bootstrap/js/dist/modal";
import {showNotification} from '@elements/alert-notification';

export function init () {
    onFind('.js-delete-item__btn', function (deleteButton) {
        on('click', function (event) {
            let url = this.getAttribute('data-url');
            let pendingRequest = axios({method: 'POST', url: url });

            showNotification({closable: true});
            showNotification(pendingRequest.then((x) => x.data));

            pendingRequest.then(({data}) => {
                if(data.success) {
                    let myModal = Modal.getOrCreateInstance(closest('.modal', this));
                    myModal.hide();
                    let itemToDelete = document.querySelectorAll('[data-item-id="'+data.id+'"]');
                    itemToDelete[0].remove();
                }
            });

            event.preventDefault();

        }, deleteButton);
    });
}