"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form--scroll', (form) => {
        on('success.ajax-form', () => {
            let result = form.querySelector('.js-ajax-form__result');
            let offset = 210;
            window.scrollTo({
                behavior: 'smooth',
                top:
                    result.getBoundingClientRect().top -
                    document.body.getBoundingClientRect().top -
                    offset,
            })
        }, form);
    });
}