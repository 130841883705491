"use strict";
import {on, findAllIn} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-form-tracking', function (form) {
        let event = form.getAttribute('data-form-tracking-event');

        let data = {
            'event': event
        }

        on('form-validation.submit', () => {
            findAllIn('.js-form-tracking__input', form).forEach((input) => {
                data[input.getAttribute('data-form-tracking-key')] = input.tagName === 'SELECT' ? input.options[input.selectedIndex].text : input.value;
            });
            window.dataLayer.push(data);
        }, form);
    });
}