"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on} from "@elements/dom-utils";

export function init() {
    onFind('.js-validate-zip',function (element) {
        let zipInput = findIn(".js-validate-zip__input", element);

        on("keyup", function(){
            if (zipInput.value.charAt(0) == " "){
                zipInput.value = zipInput.value.trim();
            }
            if (zipInput.value.charAt(zipInput.value.length-1) == " "){
                console.log(zipInput.value)
                setTimeout(function(){
                    zipInput.value = zipInput.value.trim();
                    console.log(zipInput.value)
                },1000);
            }
        }, zipInput);
    });
}