"use strict";

import {getPrefixedDataSet} from "@elements/data-set-utils";
import {onFind} from "@elements/init-modules-in-scope";
import {findIn, on, trigger, setAttribute, removeAttribute} from "@elements/dom-utils";

const defaultSelectors = {
    base: '.js-number-spinner',
    input: '.js-number-spinner__input',
    upButton: '.js-number-spinner__up',
    downButton: '.js-number-spinner__down'
};

export function init(options = {}, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createNumberSpinner(
            baseElement,
            {...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createNumberSpinner(baseElement, options = {}, selectors = defaultSelectors) {
    let inputElement = findIn(selectors.input ,baseElement),
        buttonUpElement = findIn(selectors.upButton ,baseElement),
        buttonDownElement = findIn(selectors.downButton ,baseElement);

    options = {
        ...options,
        ...transformDataSetOptions(getPrefixedDataSet('number-spinner', baseElement))
    };

    if (buttonUpElement) {
        on('click', function (evt) {
            evt.preventDefault();

            if (inputElement.getAttribute('min')) {
                options.min = +inputElement.getAttribute('min');
            }

            if (inputElement.getAttribute('max')) {
                options.max = +inputElement.getAttribute('max');
            }

            if (inputElement.getAttribute('step')) {
                options.step = +inputElement.getAttribute('step');
            }

            let newValue = +inputElement.value + 1;

            if (typeof options.max !== 'undefined') {
                newValue = Math.min(+inputElement.value + 1, options.max);
            }

            if (options.step) {
                newValue = +inputElement.value + options.step;
            }

            if (typeof(newValue) === "string"){
                inputElement.value = +parseFloat(newValue).toFixed(4);
            }else if (typeof(newValue) === "number"){
                inputElement.value = +newValue.toFixed(4);
            }else{
                inputElement.value = newValue;
            }

            trigger('change', inputElement);
            // inputElement.val(newValue).change();
        },buttonUpElement);
    }


    if (buttonDownElement) {
        on('click', function (evt) {
            evt.preventDefault();

            if (inputElement.getAttribute('min')) {
                options.min = +inputElement.getAttribute('min');
            }

            if (inputElement.getAttribute('max')) {
                options.max = +inputElement.getAttribute('max');
            }

            if (inputElement.getAttribute('step')) {
                options.step = +inputElement.getAttribute('step');
            }

            let newValue = +inputElement.value - 1;

            if (typeof options.min !== 'undefined') {
                newValue = Math.max(+inputElement.value - 1, options.min);
            }

            if (options.step) {
                newValue = +inputElement.value - options.step;
            }

            if (typeof(newValue) === "string"){
                inputElement.value = +parseFloat(newValue).toFixed(4);
            }else if (typeof(newValue) === "number"){
                inputElement.value = +newValue.toFixed(4);
            }else{
                inputElement.value = newValue;
            }

            trigger('change', inputElement);
        },buttonDownElement);
    }

    on('change', function (evt) {
        evt.preventDefault();

        if (inputElement.getAttribute('min')) {
            options.min = +inputElement.getAttribute('min');
        }

        if (inputElement.getAttribute('max')) {
            options.max = +inputElement.getAttribute('max');
        }

        if (inputElement.getAttribute('step')) {
            options.step = +inputElement.getAttribute('step');
        }

        let newValue = inputElement.value;

        if (typeof options.max !== 'undefined' && parseInt(newValue) > parseInt(options.max)) {
            inputElement.value = options.max;
        } else if (typeof options.min !== 'undefined' && newValue < options.min) {
            inputElement.value = options.min;
        }

        enableDisableButtons(inputElement, buttonDownElement, buttonUpElement, options);
    }, inputElement);

    enableDisableButtons(inputElement, buttonDownElement, buttonUpElement, options);
}


function enableDisableButtons(inputElement, buttonDownElement, buttonUpElement, options) {
    let inputValue = +inputElement.value;

    if (typeof options.max !== 'undefined') {
        if (options.max === inputValue) {
            setAttribute('disabled', true, buttonUpElement);
        } else {
            removeAttribute('disabled', buttonUpElement);
        }
    }

    if (typeof options.min !== 'undefined') {
        if (options.min === inputValue) {
            setAttribute('disabled', true, buttonDownElement);
        } else {
            removeAttribute('disabled', buttonDownElement);
        }
    }
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.fetchHeaders) {
        try {
            transformedOptions.fetchHeaders = JSON.parse(transformedOptions.fetchHeaders)
        } catch (e) {
            transformedOptions.fetchHeaders = {};
        }
    }

    return transformedOptions;
}