"use strict";
import {on, find, findAllIn, findIn, empty} from '@elements/dom-utils';
import {onFind} from "@elements/init-modules-in-scope";
import asyncAppend from "@elements/async-append";
import axios from "axios";

export function init() {
    onFind('.js-dynamic-modal-toggle', function (element) {
        let modal = find(element.getAttribute('data-bs-target'));

        on('click', function () {
            loadContent(modal, element.getAttribute('data-content-url'));
        }, element);
    });

    onFind('.js-dynamic-modal', function (modal) {
        on('hidden.bs.modal', function () {
            empty(findIn('.js-dynamic-modal__content', modal));
        }, modal);
    });

}

export function loadContent(modal, url, data, method) {
    let pendingRequest;

    if (method === 'GET') {
        pendingRequest = axios({method: 'GET', url: url, params: data });
    } else {
        pendingRequest = axios({method: 'POST', url: url, data: data });
    }

    asyncAppend({
        target: findAllIn('.js-dynamic-modal__content', modal),
        loading: findAllIn('.js-dynamic-modal__loading', modal)
    }, pendingRequest.then((x) => x.data));

    return pendingRequest;
}