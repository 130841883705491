import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, find, findIn} from "@elements/dom-utils";

export function init () {
    onFind('.js-remember-collapse', function (toggleButton) {
        let target = find(toggleButton.getAttribute('data-bs-target'));
        let input = findIn('.js-remember-collapse__input', target);
        let filter = find('.js-remember-collapse__filter')

        if(filter){
            on('shown.bs.collapse', () => {
                input.checked = true;
            }, filter);

            on('hide.bs.collapse', () => {
                input.checked = false;
            }, filter);
        }else{
            on('click', function () {
                let input = findIn('.js-remember-collapse__input', target);

                if (hasClass('collapsed', this)) {
                    input.checked = false;
                } else {
                    input.checked = true;
                }
            }, toggleButton);

        }
    });
}