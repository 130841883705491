import {findIn, setAttribute, closest, on} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";
import axios from "axios";
import {translate} from '@elements/translations';


export function init () {
    onFind(".js-profiling-search__toggle", function (button) {
        on("click", function () {
            let request = button.getAttribute("data-action");
            let pendingRequest = axios({url: request}).then((response) => response.data);
            let account = button.getAttribute("data-account")

            pendingRequest.then((response) => {
                if (response.success && account){

                    //switch button - 0 for link to enable, 1 for link to disable
                    if (response.consent){
                        button.setAttribute("data-action", request.replace(/.$/, "0"))
                        button.setAttribute("aria-label", translate('disableprofiling'))
                        button.innerHTML = translate('disableprofiling')
                    }else{
                        button.setAttribute("data-action", request.replace(/.$/, "1"))
                        button.setAttribute("aria-label", translate('enableprofiling'))
                        button.innerHTML = translate('enableprofiling')
                    }
                }
            });
        }, button)
    })
}

