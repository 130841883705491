import {initInScope, cleanUpInScope, onFind} from "@elements/init-modules-in-scope";
import {on, closest, findIn, trigger} from "@elements/dom-utils";

const selectors = {
    input: '.js-input-settings__input',
    select: '.js-input-settings__select',
    container: '.js-input-settings'
};

export function init() {
    onFind(selectors.select, (select) => {
        on('change', (evt) => {
            let container = closest(selectors.container, select);
            let input =  findIn(selectors.input, container);

            let selectedOption = select.options[select.selectedIndex];

            let steps = selectedOption.getAttribute('data-steps');
            let value = selectedOption.getAttribute('data-value');
            let min = selectedOption.getAttribute('data-min');
            let max = selectedOption.getAttribute('data-max');

            input.value = value;
            input.setAttribute('step', steps);
            input.setAttribute('min', min);
            input.setAttribute('max', max);

        }, select);
    });
}
