import { onFind } from '@elements/init-modules-in-scope'
import { onEnterViewPort } from '@elements/viewport-utils';

const selectors = {
    number: '.js-number-count-animation',
}

export function init() {
    onFind(selectors.number, (number) => {
        const dataTarget = number.dataset.numberCountAnimationTarget
        if( dataTarget === undefined || dataTarget.length === 0 || !isValidTarget(dataTarget)) return

        const goalValue = parseFloat(dataTarget)
        const totalDurationMs = 4000;

        const unit = extractUnit(dataTarget)

        onEnterViewPort(number, () => {
            animateNumber({
                begin: 0,
                target: goalValue,
                duration: totalDurationMs,
                displayElement: number,
                amountDecimalPoints: 0,
                easing: 'out', // allowed values: 'out' or 'in-out',
                unit
            })
        })
    })

    function isNumericString(input) {
        return typeof input === 'string' && !isNaN(input)
    }

    function animateNumber({begin, target, duration, displayElement, amountDecimalPoints = 0, easing = 'out', unit = ""}) {
        let start = 0;
        let end = 0

        function startAnim(timestamp) {
            start = timestamp;
            end = start + duration;
            draw(timestamp);
        }

        function draw(now) {
            if (now >= end) {
                displayElement.innerHTML= unit !== null ? `${target.toFixed(amountDecimalPoints)}${unit}` : `${target.toFixed(amountDecimalPoints)}`
                return;
            }

            let progress = (now - start) / duration;
            let val = easing === 'out' ? easeOutCubic(progress) : easeInOutCubic(progress);
            let value = begin + (target - begin) * val;
            displayElement.innerHTML = unit !== null ? `${value.toFixed(amountDecimalPoints)}${unit}` : `${value.toFixed(amountDecimalPoints)}`

            requestAnimationFrame(draw);
        }
        requestAnimationFrame(startAnim);
    }

    function easeInOutCubic(t){
        {return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
    }

    function easeOutCubic(x) {
        return 1 - Math.pow(1 - x, 3);
    }

    function isValidTarget(inputString) {
        if(isNumericString(inputString))
            return inputString // -> can be directly cast into float/integer

        else {
            const regex = /(\d+(?:\.\d+)?)\s*([%\w]+)/; // float or int with unit
            const match = inputString.match(regex);

            return match ? match[1] : false
        }
    }

    function extractUnit(inputString) {
        // Use a regular expression to match the unit at the end of the string
        const match = inputString.match(/[a-zA-Z%]+$/);

        // Check if a match was found
        if (match) {
            return inputString.includes(' ') ? ' ' + match[0]  : match[0]; // Return the matched unit
        } else {
            return null; // Return null if no unit is found
        }
    }
}
