import {onFind} from "@elements/init-modules-in-scope";
import {on, findIn, hasClass} from "@elements/dom-utils";
import {createAjaxForm} from '../libs/@elements/ajax-form';

export function init() {
    onFind('.js-ajax-form', (form) => {
        const api = createAjaxForm(form);
        let formEl = findIn('.js-ajax-form__form', form);

        on('form-validation.submit', () => {
            // if this is a hashCash form, it will be submitted on hashcashFormValid
            if (!hasClass('js-elhc-form', formEl)) {
                // triggers submit when form is validated positively
                api.submit();
            }
        }, form);

        if (hasClass('js-elhc-form', formEl)) {
            // if the response sends new hashCash values, set them and reset the nonce
            on('fetched.ajax-form', (evt) => {
                const response = evt.detail;
                if (response.elhc_stamp) {
                    form.querySelector('[name=elhc_stamp]').value = response.elhc_stamp;
                    form.querySelector('[name=elhc_nonce]').value = "";
                }
                if (response.elhc_difficulty) {
                    form.querySelector('[name=elhc_difficulty]').value = response.elhc_difficulty;
                }
            }, form);
        }

        on('hashcashFormValid', (evt) => {
            // prevent hashCash script from submitting
            evt.detail.submitForm = false;

            // submit ajax-form
            api.submit();

            // re-enable submit buttons (that were disabled by the hasCash script)
            let submitButtons = formEl.querySelectorAll('button[type="submit"], button:not([type])');
            submitButtons.forEach((el) => {
                el.removeAttribute('disabled')
            });
        
        }, formEl);

    });
}
