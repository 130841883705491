"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {on, findAllIn, setAttribute, removeAttribute, findIn, closest} from "@elements/dom-utils";

export function init() {
    onFind('.js-form-additional-fields', function (form) {
        let formInput = findIn('.js-form-additional-fields__input', form);
        let formInputAddBtn = findIn('.js-form-additional-fields__add-btn', form);
        let formParts = findAllIn('.js-form-additional-fields__part', form);
        let i = 0;

        /* show fields with input */
        if (formInput) {
            on('change', function (event) {
                if (event.target.checked) {
                    formParts.map(removeAttribute('hidden'));
                } else {
                    formParts.map(setAttribute('hidden', true));
                }
            }, formInput);
        }

        /* show fields with button */
        if (formInputAddBtn) {
            on('click', function (event) {
                formParts[i].removeAttribute('hidden');
                i++;
                if(i == formParts.length) {
                    this.setAttribute('hidden', true)
                }
            }, formInputAddBtn);
        }
    });

    /* remove fields */
    onFind('.js-form-additional-fields__remove-btn', function (button) {
        on('click', function (event) {
            let container = closest('.js-form-additional-fields__part', this);
            let inputs = findIn('input', container);
            inputs.value = "";
            container.setAttribute('hidden', true)
        }, button);
    });
}