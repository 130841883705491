"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {on, hasClass, addClass, removeClass, closest, findIn, findAllIn, find} from "@elements/dom-utils";
import {activateScrollLock, deactivateScrollLock} from "@elements/scroll-lock";

export function init () {
    const isMobile = () => matchMedia('(max-width: 767px)').matches;

    onFind('.js-nav__toggle', function (toggle) {
        let nav = closest('.js-nav-container', toggle);

        on('click', function () {
            if (hasClass('is-open', this)) {
                removeClass('is-open', this);
                removeClass('is-open', nav);
                deactivateScrollLock();
            } else {
                addClass('is-open', this);
                addClass('is-open', nav);
                activateScrollLock();
            }
        }, toggle);
    });

    onFind('.js-nav__list-item--reset-hover', function (toggleHover) {
        if (!isMobile()) {
            on('mouseover', function () {
                let parentList = closest('.js-nav__list', toggleHover);
                let listItems = findAllIn('.js-nav__list-item--reset-hover', parentList);
                listItems.forEach(function (item) {
                    removeClass('is-open', item);
                });
            }, toggleHover);
        }
    });

    onFind('.js-nav__list-toggle', function (toggleButton) {
        let list = closest('.js-nav__list', toggleButton);
        let listItems = findAllIn('.js-nav__list-item', list);
        let nav = closest('.js-nav-container', toggleButton);
        let simpleNav = false;

        if (hasClass('js-nav__list-toggle-simple', toggleButton)) {
            simpleNav = true;
        }

        on('click', function () {
            let parentItem = closest('.js-nav__list-item', toggleButton);
            let mainNav = closest('.js-nav-container', parentItem);

            if (hasClass('is-open', toggleButton)) {
                removeClass('is-open', parentItem);
                removeClass('is-open', toggleButton);

                if (!isMobile()) {
                    removeClass('is-open', mainNav);
                }
            } else {
                if (isMobile()) {
                    addClass('is-open', parentItem);

                    if (closest('.main-nav ', parentItem)) {
                        closest('.main-nav ', parentItem).scrollTop = 0;
                        addClass('is-locked', closest('.main-nav ', parentItem));
                    }
                } else {
                    addClass('is-open', mainNav);
                    listItems.forEach(function (item) {
                        removeClass('is-open', item)
                    });
                    addClass('is-open', parentItem);
                    addClass('is-open', toggleButton);
                }

                window.addEventListener("click", function _listener(evt) {
                    if (!parentItem.contains(evt.target)) {
                        removeClass('is-open', parentItem);
                        removeClass('is-open', toggleButton);
                        removeClass('is-open', nav);


                        if (!isMobile()) {
                            if(!findIn('.is-open', mainNav)) {
                                removeClass('is-open', mainNav);
                            }
                        }

                        window.removeEventListener("click", _listener, true);
                    }
                }, true);
            }

            if (!isMobile() && !simpleNav) {
                // only on desktop: open first item when opening first nav level - subnavs use links and not .js-nav__list-toggle buttons on desktop
                let listNextLevel = findIn('.main-nav__list--level-1', list);
                let firstListItem = findIn('.js-nav__list-item:first-child', listNextLevel);

                console.log(listNextLevel);
                console.log(firstListItem);

                addClass('is-open', firstListItem);
            }
        }, toggleButton);
    });

    // onFind('.js-nav__toggle-list', function (toggleButton) {
    //     let target = find(toggleButton.getAttribute('data-target'));
    //
    //     on('click', function () {
    //         if (hasClass('is-open', target)) {
    //             removeClass('is-open', target);
    //         } else {
    //             addClass('is-open', target);
    //         }
    //     }, toggleButton);
    // });
    //
    onFind('.js-nav__list-back', function (backButton) {
        on('click', function () {
            let item = closest('.js-nav__list-item', backButton);
            removeClass('is-open', item)

            if (closest('.main-nav ', item)) {
                removeClass('is-locked', closest('.main-nav ', item));
            }
        }, backButton)
    });
}