import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on, removeAttribute} from "@elements/dom-utils";

export function init() {
    onFind('.js-redirect-form', (form) => {
        on('fetched.ajax-form', (evt) => {
            const response = evt.detail;

            if (response.redirect) {
                // loading should still be shown for the redirect (ajax-form hides it after the request)
                let loadingElements = findAllIn('.js-ajax-form__loading', form);
                loadingElements.map(removeAttribute('hidden'));

                window.location.href = response.redirect;
            }
        }, form);
    });
}
