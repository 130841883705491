"use strict";

import Modal from 'bootstrap/js/dist/modal';

export function init() {
    let modalTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="modal"]'))
    let modalList = modalTriggerList.map(function (modalTriggerEl) {
        return new Modal(modalTriggerEl);
    });

    /*open modal on page load*/
    let modalId = _config.modalId;
    if(modalId && !sessionStorage.getItem(modalId)) {
        let modalShow = new Modal(document.getElementById(modalId), {})
        modalShow.show()
    }
}