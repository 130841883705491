import {onFind} from "@elements/init-modules-in-scope";

export function init() {
    onFind('.js-paper-calculator', function (calculator) {
        let c1SumaKG = 0,
            c2SumaARK = 0,
            c3SumaPLNARK = 0,
            c4SumaPLNKG = 0;

        let calculate = function (e) {

            let c1Gramatura = parseFloat(document.getElementById('uGramatura').value),
                c1FormatX = parseFloat(document.getElementById('uFormatX').value),
                c1FormatY = parseFloat(document.getElementById('uFormatY').value),
                c1IloscArkuszy = parseFloat(document.getElementById('uIloscArkuszy').value),
                c2Gramatura = parseFloat(document.getElementById('u2Gramatura').value),
                c2FormatX = parseFloat(document.getElementById('u2FormatX').value),
                c2FormatY = parseFloat(document.getElementById('u2FormatY').value),
                c2IloscKG = parseFloat(document.getElementById('u2IloscKG').value),
                c3Gramatura = parseFloat(document.getElementById('u3Gramatura').value),
                c3FormatX = parseFloat(document.getElementById('u3FormatX').value),
                c3FormatY = parseFloat(document.getElementById('u3FormatY').value),
                c3CenaPLNKG = parseFloat(document.getElementById('u3CenaPLNKG').value),
                c4Gramatura = parseFloat(document.getElementById('u4Gramatura').value),
                c4FormatX = parseFloat(document.getElementById('u4FormatX').value),
                c4FormatY = parseFloat(document.getElementById('u4FormatY').value),
                c4CenaPLNAR = parseFloat(document.getElementById('u4CenaPLNARK').value);

            if(c1Gramatura && c1FormatX && c1FormatY && c1IloscArkuszy){
                c1SumaKG = (c1Gramatura/1000) * (c1FormatX/1000) * (c1FormatY/1000) * c1IloscArkuszy;
                document.getElementById('wSumaKG').value = c1SumaKG.toFixed(2);
            }

            if(c2Gramatura && c2FormatX && c2FormatY && c2IloscKG){
                c2SumaARK = (c2IloscKG / (c2Gramatura/1000) / (c2FormatX/1000) / (c2FormatY/1000));
                document.getElementById('w2SumaARK').value = c2SumaARK.toFixed(0);
            }

            if(c3Gramatura && c3FormatX && c3FormatY && c3CenaPLNKG){
                c3SumaPLNARK = c3CenaPLNKG * (c3Gramatura/1000) * (c3FormatX/1000) * (c3FormatY/1000);
                document.getElementById('w3SumaPLNARK').value = c3SumaPLNARK.toFixed(8);
            }

            if(c4Gramatura && c4FormatX && c4FormatY && c4CenaPLNAR){
                c4SumaPLNKG = (c4CenaPLNAR / (c4Gramatura/1000) / (c4FormatX/1000) / (c4FormatY/1000));
                document.getElementById('w4SumaPLNKG').value = c4SumaPLNKG.toFixed(8);
            }
        }

        document.getElementById('uGramatura').addEventListener('input', calculate);
        document.getElementById('uFormatX').addEventListener('input', calculate);
        document.getElementById('uFormatY').addEventListener('input', calculate);
        document.getElementById('uIloscArkuszy').addEventListener('input', calculate);

        document.getElementById('u2Gramatura').addEventListener('input', calculate);
        document.getElementById('u2FormatX').addEventListener('input', calculate);
        document.getElementById('u2FormatY').addEventListener('input', calculate);
        document.getElementById('u2IloscKG').addEventListener('input', calculate);

        document.getElementById('u3Gramatura').addEventListener('input', calculate);
        document.getElementById('u3FormatX').addEventListener('input', calculate);
        document.getElementById('u3FormatY').addEventListener('input', calculate);
        document.getElementById('u3CenaPLNKG').addEventListener('input', calculate);

        document.getElementById('u4Gramatura').addEventListener('input', calculate);
        document.getElementById('u4FormatX').addEventListener('input', calculate);
        document.getElementById('u4FormatY').addEventListener('input', calculate);
        document.getElementById('u4CenaPLNARK').addEventListener('input', calculate);
    });
}