import {findIn, setAttribute, closest, on} from "@elements/dom-utils"
import {onFind} from "@elements/init-modules-in-scope";

export function init () {
    onFind(".js-info-popup", function (infoBox) {
        let closeBtn = findIn(".js-info-popup__close", infoBox);
        let closeBtnStickyElement = findIn(".js-popup-info__button-close", infoBox);
        let modalInstance = infoBox.dataset.modal;

        if(!modalInstance || modalInstance == 'undefined') {
            console.warn('data-parent not set');
            return;
        }

        if(closeBtn) {
            on('click', function () {
                sessionStorage.setItem(modalInstance, 'closed');
            }, closeBtn);
        }
    });


    onFind(".js-popup-info__button", function (stickyElement) {
        let closeBtnStickyElement = findIn(".js-popup-info__button-close", stickyElement);
        let modalBtn = closeBtnStickyElement.dataset.modal;

        if (!sessionStorage.getItem(modalBtn)) {
            document.getElementById(modalBtn).removeAttribute("hidden");
        }

        if(closeBtnStickyElement) {
            on('click', function () {
                let parent = closest('.js-popup-info__button', this);
                let btn = this.dataset.modal;
                sessionStorage.setItem(btn, 'closed');
                parent.setAttribute('hidden', true)
            }, closeBtnStickyElement);
        }

    });
}