// based on @elements/wishlist@2.1.0
import { translate } from '@elements/translations';
import { getPrefixedDataSet } from '@elements/data-set-utils';
import { showNotification } from '@elements/alert-notification';
import {findAllIn, find, on, removeClass, addClass, triggerWith} from "@elements/dom-utils";
import {onFind} from "@elements/init-modules-in-scope"
import axios from 'axios'
import 'url-search-params-polyfill'; // Edge Polyfill
import {resultTracking} from "@elements/tracking";

const defaultOptions = {
    wishlistName: '',
    activeIds: [],
    routeAdd: '',
    routeRemove: '',
    notificationOptions: {
        closable:true,
        $container: find('.js-wishlist__notification')
    },
    translations: {
        add: 'whishlist.add',
        remove: 'whishlist.remove'
    }
};
const defaultSelectors = {
    item: '.js-wishlist__btn',
    itemId: 'wishlist-id',
    productId: 'wishlist-product-id',
    wishlistId: 'wishlist-list',
    itemActiveClass: 'is-active'
};

export function createWishlist(options = defaultOptions, selectors = defaultSelectors) {
    options = {
        ...defaultOptions,
        ...options
    };
    selectors = {
        ...defaultSelectors,
        ...selectors
    };

    let _requests = {};

    const addToWishlist = function(id, productId, params = {}) {
        if(_requests[id]){
            return;
        }

        options.activeIds.push(id);
        notifyCallbacks('adding');

        _requests[id] = axios({
            url: options.routeAdd,
            method: "POST",
            data: new URLSearchParams({
                id: id,
                productId: productId,
                wishlistId: options.wishlistName ? options.wishlistName : '',
                ...params
            }),
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            return res.data
        })
        .then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('added');

            resultTracking(res);
        }).catch(function () {
            notifyCallbacks('faiild');

            options.activeIds.splice(options.activeIds.indexOf(id), 1);
            notifyCallbacks('add-failed');
        }).finally(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    const removeFromWishlist = function(id, params = {}) {
        if(_requests[id]){
            return;
        }

        options.activeIds.splice(options.activeIds.indexOf(id), 1);
        notifyCallbacks('removing');

        _requests[id] = axios({
            url: options.routeRemove,
            method: "POST",
            data: new URLSearchParams({
                id: id,
                wishlistId: options.wishlistName ? options.wishlistName : '',
                ...params
            }),
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        });

        showNotification(_requests[id], options.notificationOptions);
        _requests[id].then(function (res) {
            return res.data
        }).then(function (res) {
            options.activeIds = res.activeIds;
            notifyCallbacks('removed');
        }).catch(function () {
            options.activeIds.push(id);
            notifyCallbacks('remove-failed');
        }).finally(function () {
            _requests[id] = null;
        });

        return _requests[id];
    };

    let callbacks = [];
    const onStateChange = function(callback) {
        callbacks.push(callback);
    };
    const notifyCallbacks = function(eventname, responseTrackingData) {
        callbacks.forEach(fnc => fnc(options.activeIds));
        if(eventname){
            triggerEvent(eventname, responseTrackingData)
        }
    };
    const getState = function () {
        return options.activeIds;
    };

    const triggerEvent = function(eventname, responseTrackingData){
        triggerWith(eventname + '.wishlist',
            {
                list: selectors.wishlistId,
                activeIds: options.activeIds
            }, document
        )
    };

    const renderWishlist = function (scope = find('body')) {
        triggerEvent("renderd");
        let wishButtons = findAllIn(selectors.item, scope);

        wishButtons.forEach((el) => {
            let button = el,
                id = parseInt(button.getAttribute('data-' + selectors.itemId)),
                isInList = options.activeIds.includes(id);

            if (isInList) {
                button.setAttribute('title', translate(options.translations.remove));
                button.setAttribute('aria-label', translate(options.translations.remove));
                addClass(selectors.itemActiveClass, button)
            } else {
                button.setAttribute('title', translate(options.translations.add));
                button.setAttribute('aria-label', translate(options.translations.add));
                removeClass(selectors.itemActiveClass, button)
            }
        });
    };


    const init = function () {
        onFind(selectors.item, function (wishlistBtn) {
            const buttonClickHandler = (evt, button) => {
                const id = parseInt(button.getAttribute('data-'+ selectors.itemId));
                const productId = parseInt(button.getAttribute('data-'+ selectors.productId));

                if(options.activeIds.includes(id)){
                    removeFromWishlist(id, getPrefixedDataSet('wishlist', button));
                }else{
                    addToWishlist(id, productId, getPrefixedDataSet('wishlist', button));
                }
            };
            on('click', (evt) => buttonClickHandler(evt, wishlistBtn), wishlistBtn);
        });

        onStateChange(()  => renderWishlist());
    };

    return {
        init: init,
        initInScope: renderWishlist,
        renderWishlist: renderWishlist,
        add: addToWishlist,
        remove: removeFromWishlist,
        getState: getState,
        onstatechange: onStateChange
    }
}