"use strict";
import * as wishlistbundle from "../libs/@elements/wishlist";
import {
    findAllIn,
    find,
    findIn,
    addClass,
    removeClass,
    findAll,
    on,
    removeAttribute,
    setAttribute
} from "@elements/dom-utils";
import {onFind } from "@elements/init-modules-in-scope";

// let wishlist = null;
// if (window['_wishlist']) {
//     wishlist = wishlistbundle.createWishlist({
//         ...window['_wishlist'],
//         notificationOptions: {
//             $container: find('.js-wishlist__notification'),
//         }
//     })
// }

let myWishlists = [];
if (window['_wishlists']) {
    for(let i = 0; i < window['_wishlists'].length; i++){
        myWishlists.push(wishlistbundle.createWishlist({
            ...window['_wishlists'][i],
            notificationOptions: {
                $container: find('.js-wishlist__notification'),
            }
        }, {
            item: '.js-wishlist-' + i + '__btn',
            itemId: 'wishlist-id',
            wishlistId: 'wishlist-list',
            itemActiveClass: 'is-active'
        }));
    }
}

export function init() {
    if (myWishlists.length) {
        myWishlists.forEach((wishlist) => {
            wishlist.init();
            // init wishlist count indicator
            setCount(wishlist.getState()?.length)
        });

        myWishlists.forEach((wishlist) => {
            wishlist.init();
        });
    }

    // if(myWishlists){
    //     wishlist.init();
    // }

    // set wishlist count everytime the wishlist is changed and on init
    on('renderd.wishlist', (evt) => setCount(evt.detail?.activeIds?.length) , document);
}

export function initInScope(scope = find('body') ) {
    onFind('.js-wishlist', function (wishlistEl) {
        if (myWishlists.length) {
            myWishlists.forEach((wishlist) => {
                wishlist.initInScope(scope);

                let wishListContainer = findAllIn('.js-wishlist__container',scope);

                wishListContainer.forEach((el) => {
                    const container    = el,
                        button     = findIn('.js-wishlist__remove',container),
                        id          = container.getAttribute('data-wishlist-id');

                    const buttonClickHandler = (evt, button) => {
                        evt.preventDefault();
                        let _request = wishlist.remove(id, {showUndo: true});
                        _request.then(result => result.data).then(function(res) {
                            if(res.success){
                                container.remove();
                            }
                        })
                    };

                    on('click', (evt) => buttonClickHandler(evt, button), button);

                });
            });
        }

    });

    onFind('.js-wishlist__inquiry', function (form) {
        console.log(form)
        let items = findAllIn('.js-wishlist__inquiry-item', form);

        on('form-validation.submit', () => {
            console.log('submit');

            items.map((item) => {
                console.log(item)
                let data = {
                    'event': 'wishlist_form_sent',
                    'product_name': item.getAttribute('data-tracking-name'),
                    'product_id': item.getAttribute('data-tracking-id'),
                    'product_type': item.getAttribute('data-tracking-type')
                }

                if (findAllIn('.js-wishlist__inquiry-input', item)) {
                    findAllIn('.js-wishlist__inquiry-input', item).forEach((input) => {
                        data[input.getAttribute('data-form-tracking-key')] = input.tagName === 'SELECT' ? input.options[input.selectedIndex].text : input.value;
                    });
                }

                window.dataLayer.push(data);
            });

            form.submit();
        }, form);
    });

    myWishlists.forEach((wishlist) => {
        wishlist.initInScope(scope);
    });

    // if(wishlist){
    //     wishlist.initInScope(scope);
    // }
}

function setCount(count) {
    let elements = findAll('.js-wishlist__count');
    elements.map((item) => {
        if (count) {
            removeAttribute('hidden', item);
            addClass('is-active', item);
            item.textContent = count;
        } else {
            removeClass('is-active', item);
            setAttribute('hidden', true, item);
        }
    });
}