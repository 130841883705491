"use strict";
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, on, closest, trigger} from "@elements/dom-utils";

export function init() {
    onFind('.js-ajax-form__custom-reset', (button) => {
        on('click', () => {
            let value = button.getAttribute('data-reset-value');
            let name = button.getAttribute('data-reset-name');
            let resetElement = document.querySelectorAll('[name="'+name+'"]');

            if(value === "all"){
                let form = closest('.js-ajax-form__additional-form', button);
                let inputs = findAllIn('input', form)

                inputs.forEach((element) => {
                    element.value = '';
                    element.checked = false;
                })

                setTimeout(function(e) {
                    trigger('form-validation.submit', form);
                }, 350);
            }else{
                resetElement.forEach((element) => {
                    if(element.value === value) {
                        let form = closest('.js-ajax-form__additional-form', element);

                        if(!form){
                            form = closest('.js-ajax-form__form', element);
                        }

                        element.value = '';
                        element.checked = false;

                        setTimeout(function(e) {
                            trigger('form-validation.submit', form);
                        }, 350);
                    }
                })
            }
        }, button);
    });


    /*reset all*/
    onFind('.js-ajax-form__custom-reset-all', (button) => {
        on('click', () => {
            let container = closest('.js-ajax-form', button);
            let form = findAllIn('.js-ajax-form__additional-form', container);

            form.forEach((form) => {
                let inputs = findAllIn('input', form);
                inputs.forEach((element) => {
                    element.value = '';
                    element.checked = false;
                })

                setTimeout(function(e) {
                    trigger('form-validation.submit', form);
                }, 350);
            })

        }, button);
    });
}