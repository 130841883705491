import {onFind} from "@elements/init-modules-in-scope";
import { translate } from '@elements/translations';

export function init() {
    onFind('.js-calculator', function (calculator) {
        let weight = 0,
            value = 0;

        let calculate = function (e) {
            let _width = document.getElementById('calculatorWidth').value / 100,
                _length = document.getElementById('calculatorLength').value / 100,
                _gramage = document.getElementById('calculatorGramage').value,
                _price = document.getElementById('calculatorPrice').value;

            weight = (_width * _length) * _gramage;

            document.querySelectorAll('.js-calculator-weight').forEach(function (element) {
                element.innerHTML = weight.toFixed(2) + " " + translate("calculator.weight-unit");
            });

            let varityValue = document.querySelector("input[name='varity']:checked").value;
            if (varityValue == 'kg') {
                value = (weight * _price).toFixed(2) + " " + translate('calculator.price-per-kg');
            } else {
                value = ((_price / weight)).toFixed(2) + " " + translate('calculator.price-per-sheet');
            }

            if (_price > 0) {
                document.querySelectorAll('.js-calculator-value').forEach(function (element) {
                    element.innerHTML = value;
                });
            } else {
                document.querySelectorAll('.js-calculator-value').forEach(function (element) {
                    element.innerHTML = translate('calculator.noPrice');
                });
            }
        };

        const sanitiseFloat = (number) => {
            number = number.replace(',', '.');
            return number.match(/\d*[\.]?\d*/)[0];
        }

        const _sanitiseFloat = (e) => {
            let newVal = sanitiseFloat(e.target.value);
            e.target.value = newVal ? newVal : e.target.value;
        }

        ['calculatorWidth', 'calculatorLength', 'calculatorPrice', 'calculatorGramage'].forEach(function (id) {
            document.getElementById(id).addEventListener('input', function (e) {
                _sanitiseFloat(e);
                calculate();
            });
        });

        document.querySelectorAll('input[name="varity"]').forEach(function (input) {
            input.addEventListener('input', calculate);
        });

    });
}