// import {isParsleyForm, isValid} from "@elements/parsley-bootstrap-validation";
import {getPrefixedDataSet} from "@elements/data-set-utils";
import 'url-polyfill';
import 'url-search-params-polyfill'; // Edge Polyfill
import fetch from '@elements/fetch'; // IE10 Polyfill
import {debounce} from "debounce";
import asyncAppend from '@elements/async-append';
import formDataEntries from 'form-data-entries';
import {onFind} from "@elements/init-modules-in-scope";
import {findAllIn, findIn, on, is, off, trigger, triggerWith, setAttribute, removeAttribute, closest, hasClass} from "@elements/dom-utils";

const defaultSelectors = {
    base: '.js-ajax-form',
    result: '.js-ajax-form__result',
    loading: '.js-ajax-form__loading',
    notifications: '.js-ajax-form__notifications',
    form: '.js-ajax-form__form',
    additionalForm: '.js-ajax-form__additional-form',
    errorArea: '.js-ajax-form__error-area',
    retry: '.js-ajax-form__retry',
    link: '.js-ajax-form__link',
    reset: '.js-ajax-form__reset'
};

const defaultOptions = {
    submitOnChange: false,
    addUrlParams: false,
    submitOnReset: false,
    fetchHeaders: {},
    allowScriptTags: false,
    validator: () => true,
    onSubmit: () => {}
};

export function init(options = defaultOptions, selectors = defaultSelectors) {
    onFind(selectors.base, function (baseElement) {
        createAjaxForm(
            baseElement,
            {...defaultOptions, ...options},
            {...defaultSelectors, ...selectors}
        );
    });
}

export function createAjaxForm(baseElement, options = defaultOptions, selectors = defaultSelectors) {
    let pendingRequest = null;
    let form = findIn(selectors.form, baseElement);
    let results = findAllIn(selectors.result, baseElement);
    let loadingElements = findAllIn(selectors.loading, baseElement);
    let notifications = findAllIn(selectors.notifications, baseElement);
    let errorAreas = findAllIn(selectors.errorArea, baseElement);

    options = {
        ...defaultOptions,
        ...options,
        ...transformDataSetOptions(getPrefixedDataSet('ajax-form', baseElement))
    };

    // form
    onFind(selectors.form, function (form) {
        const submitHandler = evt => {
            evt.preventDefault();
            evt.stopImmediatePropagation(); // otherwise .on('submit.ajax-form') would be called twice
            submitForm(form, evt);
        };

        const changeHandler = debounce(() => {
            submitForm(form);
        }, 200);

        on('submit', submitHandler, form);

        if (options.submitOnChange) {
            on('change', changeHandler, form);
        }

        return () => {
            off('submit', submitHandler, form);
            off('change', changeHandler, form);
        }
    }, baseElement);

    // additional forms
    onFind(selectors.additionalForm, function (additionalForm) {
        const submitHandler = evt => {
            evt.preventDefault();
            submitForm(additionalForm, evt);
        };

        const changeHandler = debounce(function () {
            submitForm(additionalForm);
        }, 200);

        on('submit', submitHandler, additionalForm);

        if (options.submitOnChange == "true" || additionalForm.dataset.ajaxFormSubmitOnChange == "true") {
            if(additionalForm.dataset.ajaxFormSubmitOnChange == "false"){
                return
            }else{
                on('change', changeHandler, additionalForm)
            }
        }

        return () => {
            off('submit', submitHandler, additionalForm);
            off('change', changeHandler, additionalForm);
        }
    }, baseElement);

    // retry
    findAllIn(selectors.retry, baseElement)
        .map(on('click', (evt) => {
            evt.preventDefault();

            if (lastLoadParams) {
                load(...lastLoadParams);
            }
        }));

    // links
    onFind(selectors.link, (link) => {
        function linkClickHandler(evt) {
            evt.preventDefault();

            let closestForm = closest('form', link);

            let href = link.getAttribute('href') || link.dataset.href;
            let action = closestForm ? getActionByForm(closestForm) : form.dataset.action || form.getAttribute('action');
            let params = new URL(href, location.origin).searchParams;

            pendingRequest = load(action, 'GET', params, href);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error) => {
                    if ((!error || error.name !== 'AbortError')) {
                        pendingRequest = null;
                    }
                });
        }

        on('click', linkClickHandler, link);

        return () => {
            off('click', linkClickHandler, link);
        }
    }, baseElement);


    // Reset buttons
    onFind(selectors.reset, (resetButton) => {
        function resetClickHandler(evt) {
            if(resetButton.tagName !== 'INPUT' && resetButton.type !== 'submit'){
                evt.preventDefault()
            }

            let inputs = findAllIn('input, select', form),
                target = evt.target;

            if(!hasClass(selectors.reset, target)){
                target = closest(selectors.reset,target);
            }

            let resetName = target.dataset.resetName,
                resetValue =  target.dataset.resetValue,
                inputsSelects = [];

            if (resetName) {
                let selectorName = resetName
                    .split(',')
                    .map(name => `[name="${name.trim()}"]`)
                    .join(', ');

                inputs = inputs.filter(is(selectorName));
            }

            if (resetValue) {
                let selectorValue = resetValue.toString()
                    .split(',')
                    .map(value => `[value="${value.trim()}"]`)
                    .join(', ');

                inputs.map(function(input) {
                    if(input.nodeName === 'SELECT') {
                        inputsSelects.push(input);
                    }
                });

                inputs = inputs.filter(is(selectorValue));
            }

            inputs = [...inputs, ...inputsSelects];

            inputs.map(function(input) {
                if(input.type !== 'radio' && input.type !== 'checkbox') {
                    input.value = '';
                }
            });
            inputs.filter(is('[type="radio"], [type="checkbox"]')).map(input => input.checked = false);
            inputs.map(trigger('change'));
            triggerWith('reset.ajax-form', inputs, baseElement);

            if (options.submitOnReset) {
                submitForm(form);
            }

        }

        on('click', resetClickHandler, resetButton);

        return () => {
            off('click', resetClickHandler, resetButton);
        }
    }, baseElement);

    //extend ajax form
    onFind('.js-ajax-form__submit', (submitButton) => {
        const submitHandler = evt => {
            evt.preventDefault();
            evt.stopImmediatePropagation();
            submitForm(findIn(selectors.form, closest(selectors.base, submitButton)), evt, submitButton.getAttribute('formaction') ? submitButton.getAttribute('formaction') : null);
        };

        on('click', submitHandler, submitButton);

        return () => {
            off('click', submitHandler, submitButton);
        }
    });

    let lastLoadParams = null; // for retry
    function load(url, method, params, historyUrl) {
        method = method || "GET";
        lastLoadParams = [url, method, params, historyUrl];

        if (options.addUrlParams) {
            history.replaceState(history.state, document.title, historyUrl || url);
        }

        // add base url to params (path)
        params.append('baseUrl', location.pathname);

        if (method.toUpperCase() === "GET") {
            params.append('ajax', 1);
            // Add ajax param to differentiate between and ajax requests and page request.
            // Otherwise Chrome caches these results as normal pages and returns them from cache if the back button is pressed
            url = addSearchParamsToUrl(url, params);
        }

        let request = fetch(url, {
            method: method,
            headers: {
                'pragma': 'no-cache',
                'cache-control': 'no-cache',
                ...options.fetchHeaders
            },
            ...(method.toUpperCase() !== "GET" ? {
                body: new URLSearchParams(params)
            } : {})
        });

        triggerWith('fetch.ajax-form', request, baseElement);


        let targetsByResultId = {};
        results.forEach((element) => {
            let resultId = element.dataset.resultId || 'default';
            if (targetsByResultId[resultId]) {
                targetsByResultId[resultId] = [...targetsByResultId[resultId], element];
            } else {
                targetsByResultId[resultId] = [element];
            }
        });

        asyncAppend(
            {
                target: targetsByResultId,
                loading: loadingElements,
                notifications: notifications,
                options: {
                    allowScriptTags: options.allowScriptTags
                }
            },
            request
        )
            .then((result) => {
                let content = result.html || result.content;
                // result.hasNoContent can be set if the response contains no html (e.g. only a redirect url)
                if ((content || result.hasNoHtml) && result.success !== false) {
                    trigger('success.ajax-form', baseElement);
                    errorAreas.map(setAttribute('hidden', 'hidden'));

                    let newForm = findIn(selectors.form, baseElement);
                    if (newForm) {
                        form = newForm;
                    }


                } else {
                    trigger('failed.ajax-form', baseElement);
                    errorAreas.map(removeAttribute('hidden'));
                }

                triggerWith('fetched.ajax-form', result, baseElement);
            })
            .catch(() => {
            });

        // Unpack json response body if the promise was created via fetch
        // Otherwise the HTTP-Server error is not caught.
        // The fetch promise itself resolves (even with a http error)
        request.then(response => (response
            && response.json
            && typeof response.json === 'function'
            && response.clone
            && typeof response.clone === 'function')
            ? response.clone().json()
            : response
        ).catch((error, requestState) => {
            if (!error || error.name !== 'AbortError') {
                console.error(error);
                trigger('failed.ajax-form', baseElement);
                errorAreas.map(removeAttribute('hidden'));
            }
        });

        return request;
    }

    function submitForm(form, evt, formAction) {
        let submitBtn;
        let action;

        if(evt !== undefined && evt.submitter){
            submitBtn = evt.submitter;
            if(submitBtn.getAttribute('formaction')){
                action = submitBtn.getAttribute('formaction');
            }
        } else if (formAction) {
            action = formAction;
        } else {
            action = getActionByForm(form);
        }

        return submit(
            action,
            form.dataset.method || form.getAttribute('method'),
            submitBtn
        )
    }

    function submit(action, method, submitBtn) {
        action = action || getActionByForm(form);
        method = method || form.dataset.method || form.getAttribute('method');

        // create promise to resolve/reject in right order (important for loading-indicator with multiple submissions)
        let readyToSubmit = new Promise(function (resolve, reject) {
            if (pendingRequest && pendingRequest.abort) {
                pendingRequest.abort();
                pendingRequest.catch(resolve);
                pendingRequest = null;
            } else {
                resolve();
            }
        });

        readyToSubmit.then(function () {
            if (typeof options.validator === "function" && !options.validator({
                baseElement,
                form
            })) {
                return;
            }

            trigger('submit.ajax-form', baseElement);

            action = action || getActionByForm(form);
            method = method || form.dataset.method || form.getAttribute('method');
            let formDataEntries = getFormDataEntries();

            if(submitBtn !== undefined && submitBtn.name.length > 0 && submitBtn.value.length > 0){
                formDataEntries.push([submitBtn.name, submitBtn.value]);
            }

            let params = new URLSearchParams(formDataEntries);

            onFind(selectors.link, (link) => {
                if (link.classList.contains("active")){
                    let hreflink = link.getAttribute('href') || link.dataset.href;
                    let paramslink = new URL(hreflink, location.origin).searchParams;

                    //check if parentCategoryIds or categoryIds in url
                    if (Array.from(paramslink.getAll('parentCategoryIds')).length !== 0){
                        let parentCategoryIds = Array.from(paramslink.getAll('parentCategoryIds'))
                        params.append('parentCategoryIds', parentCategoryIds)
                    }else if(Array.from(paramslink.getAll('categoryIds')).length !== 0){
                        let categoryIds = Array.from(paramslink.getAll('categoryIds'))
                        params.append('categoryIds', categoryIds)
                    }
                }
            })

            /* find empty values */
            let keysForDel = [];
            params.forEach((value, key) => {
                if (value == '') {
                    keysForDel.push(key);
                }
            });

            keysForDel.forEach(key => {
                params.delete(key);
            });

            params.toString();

            options.onSubmit({
                $element: baseElement,
                options,
                formData: getFormData()
            });

            let url = new URL(location.href);
            url.searchParams.delete('page');

            let searchparams_key = []
            for(let [key, value] of url.searchParams.entries()){
                searchparams_key.push(key)
            }

            for( let searchkey in searchparams_key ){
                url.searchParams.delete(searchparams_key[searchkey])
            }

            url = addSearchParamsToUrl(url, params);

            pendingRequest = load(action, method, params, url);

            pendingRequest
                .then(() => pendingRequest = null)
                .catch((error) => {
                    if (!error || error.name !== 'AbortError') {
                        pendingRequest = null;
                    }
                });
        });
    }

    function getFormData() {
        return createFormData([form, ...findAllIn(selectors.additionalForm, baseElement)]);
    }

    function getFormDataEntries() {
        return createFormDataEntries([form, ...findAllIn(selectors.additionalForm, baseElement)]);
    }

    let api = {
        submit,
        getFormData,
        getFormDataEntries // todo add to doku
    };

    baseElement.ajaxForm = api;

    return api;
}

function addSearchParamsToUrl(url, searchParams) {
    url = new URL(url, location.origin);

    let searchParamsArray = Array.from(searchParams);
    searchParamsArray.forEach(([name]) => url.searchParams.delete(name));
    searchParamsArray.forEach(([name, value]) => url.searchParams.append(name, value));

    return url;
}

function createFormData(forms) {
    let formData = new FormData();
    forms.map(form => {
        for (var pair of formDataEntries(form)) {
            formData.append(...pair);
        }
    });

    return formData;
}

function createFormDataEntries(forms) {
    let formDataArray = [];

    forms.map(form => {
        // unchecked checkboxes and radios needs to be added manually to formDataArray
        let selectors = findAllIn('input[type="radio"], input[type="checkbox"]', form);
        let selectorNames = [];

        // selects needs to be added manually to formDataArray
        let selects = form.getElementsByTagName("SELECT");

        selectors = [...selectors, ...selects];

        selectors.map(function(selector) {
            selectorNames.push(selector.name);
        });

        for (var pair of formDataEntries(form)) {
            formDataArray.push(pair);
        }

        let existingNames = formDataArray.map(entry => entry[0]);
        selectorNames = [...new Set(selectorNames)];

        selectorNames.forEach(function (name) {
            let newEntry = [name, ""];
            if(!existingNames.includes(name)) {
                formDataArray.push(newEntry);
            } else {
                formDataArray.filter(item => item !== newEntry);
            }
        });
    });

    return formDataArray;
}

function transformDataSetOptions(options = {}) {
    let transformedOptions = {...options};

    if (transformedOptions.fetchHeaders) {
        try {
            transformedOptions.fetchHeaders = JSON.parse(transformedOptions.fetchHeaders)
        } catch (e) {
            transformedOptions.fetchHeaders = {};
        }
    }

    if (transformedOptions.allowScriptTags) {
        transformedOptions.allowScriptTags = !!(transformedOptions.allowScriptTags === "true" || transformedOptions.allowScriptTags === true)
    }

    return transformedOptions;
}

function getActionByForm(form) {
    return form.dataset.action || form.getAttribute('action') || location.pathname
}
