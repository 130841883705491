import {onFind} from "@elements/init-modules-in-scope";
import {addClass, removeClass, on} from "@elements/dom-utils";
import {isScrollLock} from "@elements/scroll-lock";

export function init () {
    onFind('.js-scroll-top', ((scrollTop) => {
        let scrollPos = window.pageYOffset;
        const addClassOnScroll = () => addClass('is-affix', scrollTop);
        const removeClassOnScroll = () => removeClass('is-affix', scrollTop);


        window.addEventListener('scroll', function() {
            scrollPos = window.pageYOffset;

            if (scrollPos === 0 && !isScrollLock()) {
                removeClassOnScroll();
            } else {
                if (scrollPos >= 500) {
                    addClassOnScroll();
                } else {
                    if (!isScrollLock()) {
                        removeClassOnScroll();
                    }
                }
            }
        }, false);


        on('click', () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }, scrollTop);
    }));
}