import Autocomplete from '../libs/autocomplete.esm.js';
import {initInScope, onFind} from "@elements/init-modules-in-scope";
import {translate} from '@elements/translations';
import axios from "axios";

const selectors = {
    base: '.js-autocomplete',
};

// the input with the base class un-/checks all other inputs with the child class that are in the container
export function init() {
    onFind(selectors.base, (autocompleteEl) => {

        let remoteUrl = autocompleteEl.dataset.autocompleteRemoteUrl;
        let wildcard = "%QUERY%";
        // get dataset as object
        let categories = JSON.parse(autocompleteEl.dataset.autocompleteCategories);
        new Autocomplete("mainSearch", {
            clearButton: false,
            onSearch: ({ currentValue }) => {
                const api = remoteUrl.replace(wildcard, encodeURIComponent(currentValue));
                return axios.get(api)
                        .then((response) => {
                            return response.data;
                        })
                        .catch((error) => {
                            console.error(error);
                        });
            },
            onResults: ({ currentValue, matches, template, classGroup }) => {

                let rightItems = [];
                let middleItems = [];
                let leftItems = [];
                let leftItemsEmpty = false;
                // loop through the categories and create the result items
                categories.map((category) => {

                    let categoryItems = matches[category.name];

                    // if we have items for the category OR if it is the searchSuggestions category (which is always shown)
                    if (categoryItems || 'searchSuggestions' === category.name) {
                        let tempItems = '';

                        if (categoryItems) {
                            tempItems = categoryItems.map((data, index, array) => {
                                // create the regex for the highlight
                                let regexp = new RegExp(currentValue, 'gi');
                                if (data.title) {
                                    if (data.type === "product" || data.type === "article") {
                                        return `
                                            <div
                                                class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item autocomplete-image stretch-link" 
                                                data-event-tracking-event="website_search"
                                                data-event-tracking-search_query="${currentValue}"
                                                data-event-tracking-result_type="search_${data.type}"
                                                data-event-tracking-clicked_result="${data.title}">
                                                ${ /* hack to show only the title in the autocomplete (e.g. when pressing enter) */'' }
                                                <div hidden>${data.title}</div>
                                                <div class='autocomplete-image__img-container'>
                                                    <div class='ratio ratio-4x3'>
                                                        ${ /* use thumbnail "autocomplete-image" */'' }
                                                        <img class="ratio-item" src='${data.imageUrl}' alt="">
                                                    </div>
                                                </div>
                                                <div class='autocomplete-image__text'>
                                                    <a href="${data.url}" class="font-bold stretch-link__link">${data.title.replace(regexp, '<mark>$&</mark>')}</a>${data.text ? "<br/>" + data.text.replace(regexp, '<mark>$&</mark>') : ""}
                                                </div>
                                            </div>`

                                    } else {
                                        return `<a  href="${data.url}" class="autocomplete__suggestion autocomplete__selectable js-autocomplete__item"
                                                     data-event-tracking-event="website_search" data-event-tracking-search_query="${currentValue}"
                                                     data-event-tracking-result_type="search_suggestion" data-event-tracking-clicked_result="${data.title}">
                                                    ${data.title.replace(regexp, '<mark>$&</mark>')}
                                                </a>`
                                    }

                                }
                            }).join('');
                        }

                        let categoryOverviewUrl = "";
                        if (category.overviewUrl) {
                            categoryOverviewUrl = category.overviewUrl
                                + (category.overviewUrl.indexOf('?') >= 0 ? '&' : '?')
                                + 'searchTerm=' + currentValue;
                        }

                        // set the icon class for the category header
                        let icon = '';
                        switch (category.name) {
                            case 'products':
                                icon = 'variety'
                                break;
                            case 'searchSuggestions':
                                icon = 'search'
                                break;
                            case 'news':
                                icon = 'speaker'
                                break;
                            case 'productCategory':
                                icon = 'tag-regular'
                                break;
                            case 'magazine':
                                icon = 'book-open-cover-regular'
                                break;
                            case 'references':
                                icon = 'award-regular'
                                break;
                        }

                        let header = `<div class="autocomplete__dataset-header">
                                            <a href="${categoryOverviewUrl}" class="autocomplete__dataset-header-hl hover-primary">
                                            <span class="autocomplete__dataset-header-icon icon icon-${icon}"></span>${category.title}</a>
                                      </div>`;

                        let footer = '';

                        // search Suggestions are shown in the left area
                        console.log('change');
                        if (category.name === 'searchSuggestions') {
                            leftItemsEmpty = !tempItems;
                            leftItems.push(`<div class="autocomplete__dataset">${header}${tempItems}${footer}</div>`);
                        } else {
                            if (tempItems) {
                                // all other categories are shown in the right area and have footers
                                footer = `<div class="autocomplete__dataset-footer">
                                               <a href="${categoryOverviewUrl}" class="btn btn-link autocomplete__show-more-btn">${translate('autocomplete.show-more')}</a>
                                          </div>`;
                                if (category.name === 'products' || category.name === 'productCategory') {
                                    middleItems.push(`<div class="autocomplete__dataset">${header}${tempItems}${footer}</div>`);
                                } else {
                                    rightItems.push(`<div class="autocomplete__dataset">${header}${tempItems}${footer}</div>`);
                                }
                            }
                        }
                    }
                }); // end of categories.map

                // checking if we have results if we don't
                // take data from the noResults callback
                return matches === 0
                    ? template
                    : `<li class="autocomplete__right-area">
                            <div class="row gx-lg-5 gy-36px">
                                ${middleItems.length ? (`<div class="col-md-6">${middleItems.join('')}</div>`) : '' }
                                <div class="col-md-6">${rightItems.join('')}</div>
                            </div>
                        </li>
                        <li class="autocomplete__left-area ${leftItemsEmpty ? 'autocomplete__left-area--empty' : ''}">${leftItems.join('')}</li>`;
            },
            onOpened: ({results}) => {
                initInScope(results);
            },
        });

    });
}
